import { graphql } from "gatsby"
import { GatsbyImageProps } from "gatsby-image"
import React, { useEffect, useState } from "react"

import BlogPostsList from "../../components/BlogPostsList"
import Layout from "../../components/Layout"
import { ICategory } from "../../components/PostEntry"
import SEO from "../../components/Seo"
import { formatPostsNodesToPostEntries } from "../../templates/utils"

import "./blog.scss"

export interface IBlogPageQuery {
  data: {
    blogPosts: {
      nodes: {
        fields: {
          slug: string
        }
        frontmatter: {
          image: {
            childImageSharp: GatsbyImageProps
          }
          title: string
          category: string[]
          date: string
        }
      }[]
    }
    categories: {
      nodes: {
        frontmatter: ICategory
      }[]
    }
  }
}

const Blog: React.FunctionComponent<IBlogPageQuery> = ({ data }) => {
  const postsNodes = data.blogPosts.nodes
  const categories = data.categories.nodes.map(categoryNode => ({
    title: categoryNode.frontmatter.title,
    slug: categoryNode.frontmatter.slug,
  }))
  const posts = formatPostsNodesToPostEntries(postsNodes, categories)
  const uniqueCategorySlugs: string[] = Array.from(
    new Set(
      posts.flatMap(post => {
        return post.category.map(postCategory => postCategory.slug)
      })
    )
  )
  const [displayedCategories, setDisplayedCategories] = useState(
    uniqueCategorySlugs
  )
  const [displayedBlogPosts, setDisplayedBlogPosts] = useState(posts)
  const updateCategoryFilter = (categories: string[]) =>
    setDisplayedCategories(categories)
  useEffect(() => {
    if (displayedCategories.length === uniqueCategorySlugs.length) {
      setDisplayedBlogPosts(posts)
    } else {
      const filteredBlogPosts = posts.filter(post => {
        const postCategorySlugs = post.category.map(
          postCategory => postCategory.slug
        )
        return postCategorySlugs.includes(displayedCategories[0])
      })
      setDisplayedBlogPosts(filteredBlogPosts)
    }
  }, [displayedCategories])
  return (
    <Layout activePage="blog">
      <SEO
        title="Blog"
        description="La Consigne GreenGo est une app' mobile permettant d’emprunter facilement des emballages réutilisables et consignés dans tout type de structures : restaurants, cafétérias d’entreprises et grande distribution."
        keywords={[
          "blog",
          "actualités",
          "consigne",
          "emballage",
          "zéro",
          "déchets",
          "restauration",
        ]}
      />
      <div className="blog section-padding">
        <h2 className="heading heading--section-title">
          Une histoire qui se construit ensemble
        </h2>
        <h3 className="heading heading--section-subtitle">
          Découvrez nos articles et nos actualités
        </h3>
        <div className="container">
          <div className="blog__filter-wrapper">
            <button
              className={`blog__filter shadow--md link button  button--color-${
                displayedCategories.length === uniqueCategorySlugs.length
                  ? "pink"
                  : "white"
              }`}
              onClick={() => updateCategoryFilter(uniqueCategorySlugs)}
            >
              Toutes catégories
            </button>
            <div className="blog__category-filters">
              {uniqueCategorySlugs.map(categorySlug => {
                return (
                  <button
                    className={`blog__filter shadow--md link button button--color-${
                      displayedCategories.length === 1 &&
                      displayedCategories[0] === categorySlug
                        ? "pink"
                        : "white"
                    }`}
                    onClick={() => updateCategoryFilter([categorySlug])}
                    key={categorySlug}
                  >
                    {categories.find(
                      categoryNode => categoryNode.slug === categorySlug
                    )?.title ?? "Autre"}
                  </button>
                )
              })}
            </div>
          </div>
          <BlogPostsList posts={displayedBlogPosts} />
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  fragment BlogPost on Mdx {
    fields {
      slug
    }
    frontmatter {
      image {
        childImageSharp {
          fluid(
            maxWidth: 500
            maxHeight: 350
            srcSetBreakpoints: [350, 500, 1000]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      title
      category
      date
    }
  }

  query GetAllBlogPosts {
    blogPosts: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { templateKey: { eq: "BlogPost" } } }
    ) {
      nodes {
        ...BlogPost
      }
    }
    categories: allMdx(
      filter: { frontmatter: { templateKey: { eq: "BlogCategory" } } }
    ) {
      nodes {
        frontmatter {
          templateKey
          slug
          title
        }
      }
    }
  }
`
